body {
  background-color: #E3F2FD;
  font-family: Roboto, sans-serif;
}

.carousel-item {
  border: 2px solid lightgrey;
  border-radius: 15px;
}

ul li {
  margin-top: 5px;
}

svg {
  margin-bottom: 5px;
}

ul li, h1, h2, h3, p {
  color: #212121;
}

small {
  margin-top: 10px;
  margin-left: 5px;
  text-align: right;
}

img {
  width: 50%;
  height: auto;
}